import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div id="youtube" className="youtube">
        <HeaderText/>
        <div className="youtube-items">
            <Item
                link={<iframe title="Lihtne viis, kuidas hinnata, kas saad suvel päikesest piisavalt D-vitamiini!"
                              width="380" height="250"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              src="https://www.youtube.com/embed/nhcsoC8651E">
                </iframe>}
            >
            </Item>
            <Item
                link={<iframe title="Spetsiaalselt naistele loodud D-vitamiin!" width="380" height="250"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              src="https://www.youtube.com/embed/keV7Yo1YYOE">
                </iframe>}
            >
            </Item>
            <Item
                link={<iframe title="Gerli Padar räägib oma kogemusest D-vitamiiniga" width="380" height="250"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              src="https://www.youtube.com/embed/a1vinKu2_hQ">
                </iframe>}
                className="item3-margin"
            >
            </Item>
        </div>
    </div>
));