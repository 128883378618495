import React from 'react';
import BuyNowButton from '../../common/buy-now-button';
import './index.css';
import cx from 'classnames';
import {injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const topLeft = cx({
        'vitamin-for-women__text': true,
        'vitamin-for-women__top-left': true,
        'vitamin-for-women__top-left-ru': locale === 'ru',
        'vitamin-for-women__text-ru': locale === 'ru'
    });
    const topRight = cx({
        'vitamin-for-women__text': true,
        'vitamin-for-women__top-right': true,
        'vitamin-for-women__top-right-ru': locale === 'ru',
        'vitamin-for-women__text-ru': locale === 'ru'
    });
    const BottomLeft = cx({
        'vitamin-for-women__text': true,
        'vitami-for-women__bottom-left': true,
        'vitami-for-women__bottom-left-ru': locale === 'ru',
        'vitamin-for-women__text-ru': locale === 'ru'
    });
    return (
        <div className="vitamin-for-women">
            <div
                className="vitamin-for-women__header"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "vitamin.women.header"})}}
            />
            <div
                className={topLeft}
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "vitamin.women.top.left"})}}
            />
            <div
                className={topRight}
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "vitamin.women.top.right"})}}
            />
            <div
                className={BottomLeft}
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "vitamin.women.bottom.left"})}}
            />
            <img
                className="vitamin-for-women__bottle"
                src="/static/images/vitamin-for-women-bottle.png"
            />
            <img
                className="vitamin-for-women__bg"
                src="/static/images/vitamin-for-women-mobile.png"
                />
            <span className="vitamin-for-women__button">
                <BuyNowButton link={"https://www.ipharma.ee/vitamin-d3-pro-expert-2500-iu-olikapslid-naistele-n60"} large />
            </span>
        </div>
    )
});
