import React from 'react';
import BuyNowButton from '../../common/buy-now-button'
import './index.css';

export default () => (
    <div className="middle-buy-now">
        <img
            className="middle-buy-now__bottles"
            src="/static/images/top-banner-bottles.png"
        />
        <span className="middle-buy-now__button">
            <BuyNowButton link={"https://www.ipharma.ee/vitamiinid/d-vitamiin"} large />
        </span>
    </div>
);
